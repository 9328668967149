import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
    AuthFunctions,
    AuthState,
    UserAuthDetails,
    AuthData,
} from 'entities/authentication';

import { apiBase } from 'api/api';
import { authApi } from 'api/auth';
import i18next from 'i18next';

const AuthContext = React.createContext({});
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const useAuth = () => React.useContext(AuthContext) as AuthData;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const AuthProvider = (props: any): JSX.Element => {
    // Access search parameters

    const [state, setState] = useState<AuthState>({
        name: '',
        accessToken: '',
        isAuthenticated: false,
        id: 0,
        organisationID: 0,
        loading: false,
        error: null,
        email: '',
        isAdmin: false,
        permissions: [],
        language: 'dansk',
        type: 0,
        interceptorID: 0,
        allow_finance: 0,
        allow_sub_rules: 0,
        allow_ticket_rules: 0,
    });

    const setLanguage = (language: string) => {
        if (language === state.language) return;
        setState((state) => ({ ...state, language }));
        i18next.changeLanguage(language);
    };

    // TODO: revisited this auth flow when getting closer to prod
    useEffect(() => {
        const tknDetails = Cookies.getJSON('pk-admin');
        if (!tknDetails) {
            setState((s) => ({
                ...s,
                loading: false,
            }));

            return;
        }

        const { language } = tknDetails;
        setLanguage(language);

        const validateToken = async (tkn: string): Promise<boolean> => {
            const tokenIsValid = await authApi.validateToken({
                token: tkn,
            });

            return tokenIsValid === 200;
        };

        if (tknDetails && tknDetails.token && tknDetails.token.length !== 0) {
            validateToken(tknDetails.token).then((isValid) => {
                if (!isValid) {
                    Cookies.remove('pk-admin');
                    return;
                }

                if (isValid) {
                    setState((s) => ({
                        ...s,
                        name: tknDetails.username,
                        accessToken: tknDetails.token,
                        isAuthenticated: true,
                        id: tknDetails.userID,
                        organisationID: tknDetails.organisationID,
                        email: tknDetails.email,
                        permissions: tknDetails.permissions,
                        isAdmin: tknDetails.isAdmin,
                        loading: false,
                        language,
                        type: tknDetails.type,
                        allow_finance: tknDetails.allow_finance,
                        allow_sub_rules: tknDetails.allow_sub_rules,
                        allow_ticket_rules: tknDetails.allow_ticket_rules,
                    }));
                    apiBase.interceptors.request.use(
                        (config) => {
                            config.headers.Token = tknDetails.token;
                            return config;
                        },
                        () => {
                            setState((s) => ({
                                ...s,
                                name: '',
                                accessToken: '',
                                isAuthenticated: false,
                                userID: 0,
                                organisationID: 0,
                                email: '',
                                loading: false,
                                error: null,
                                language,
                                type: 0,
                                allow_finance: 0,
                                allow_sub_rules: 0,
                                allow_ticket_rules: 0,
                            }));
                            throw new Error('token no longer valid');
                        }
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO: revisited this auth flow when getting closer to prod
    const login = async (e: React.FormEvent, payload: UserAuthDetails) => {
        e.preventDefault();

        setState({
            ...state,
            loading: true,
        });

        const res = await authApi.authenticate({
            userName: payload.username,
            password: payload.password,
        });
        if (!res.authenticated) {
            setState({
                ...state,
                name: '',
                accessToken: '',
                isAuthenticated: false,
                id: 0,
                organisationID: 0,
                email: '',
                loading: false,
                error: 'The username or password is incorrect',
                isAdmin: false,
                type: 0,
                allow_finance: 0,
                allow_sub_rules: 0,
                allow_ticket_rules: 0,
            });
            return;
        }

        const interceptorID = apiBase.interceptors.request.use(
            (config) => {
                config.headers.Token = res.token;
                return config;
            },
            (error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        );

        Cookies.set('pk-admin', {
            userID: res.user_id,
            token: res.token,
            organisationID: res.organisation_id,
            email: res.email,
            username: res.username,
            permissions: res.permissions,
            isAdmin: res.is_admin,
            language: state.language,
            type: res.type,
            interceptorID,
            allow_finance: res.allow_finance,
            allow_sub_rules: res.allow_sub_rules,
            allow_ticket_rules: res.allow_ticket_rules,
        });

        setState({
            ...state,
            isAuthenticated: res.authenticated,
            accessToken: res.token,
            id: res.user_id,
            organisationID: res.organisation_id,
            name: res.username,
            email: res.email,
            permissions: res.permissions,
            loading: false,
            error: null,
            isAdmin: res.is_admin,
            type: res.type,
            interceptorID,
            allow_finance: res.allow_finance,
            allow_sub_rules: res.allow_sub_rules,
            allow_ticket_rules: res.allow_ticket_rules,
        });

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('userSessionExpired');
        let newUrl = `${window.location.pathname}`;
        if (searchParams.toString().length > 0) {
            newUrl = `${window.location.pathname}?${searchParams}`;
        }
        window.history.pushState({ path: newUrl }, '', newUrl);
    };

    // TODO: revisit this
    const logout = async () => {
        // remove the pk-admin cookie
        Cookies.set('pk-admin', {
            language: state.language,
        });
        Cookies.remove('pk-base-admin');
        apiBase.interceptors.request.use(
            (config) => {
                delete config.headers.Token;
                return config;
            },
            (error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        );
        apiBase.interceptors.request.eject(state.interceptorID);

        // reset state (just for good measure)
        setState((state) => ({
            ...state,
            accessToken: '',
            name: '',
            isAuthenticated: false,
            id: 0,
            organisationID: 0,
            loading: false,
            error: null,
            email: '',
            permissions: [],
            isAdmin: false,
            type: 0,
            allow_finance: 0,
            allow_sub_rules: 0,
            allow_ticket_rules: 0,
        }));
    };

    const loadingState = state.loading;
    const { error } = state;
    const authentication: AuthFunctions = {
        login,
        logout,
    };

    return (
        <AuthContext.Provider
            value={{
                id: state.id,
                name: state.name,
                organisationID: state.organisationID,
                accessToken: state.accessToken,
                isAuthenticated: state.isAuthenticated,
                email: state.email,
                permissions: state.permissions,
                isAdmin: state.isAdmin,
                loadingState,
                authentication,
                error,
                language: state.language,
                setLanguage,
                type: state.type,
                allow_finance: state.allow_finance,
                allow_sub_rules: state.allow_sub_rules,
                allow_ticket_rules: state.allow_ticket_rules,
            }}
            {...props}
        />
    );
};

export default AuthProvider;
