import { authBase, apiBase } from 'api/api';
import {
    AuthenticateResponse,
    AuthenticateRequest,
    ValidateTokenRequest,
} from 'api/interface/authenticate';

const authenticate = async (
    req: AuthenticateRequest
): Promise<AuthenticateResponse> => {
    const res = await authBase.post(`/login`, {
        user_name: req.userName,
        password: req.password,
    });
    if (res?.status !== 200) {
        return {
            authenticated: false,
            token: '',
            organisation_id: 0,
            user_id: 0,
            username: '',
            permissions: [],
            email: '',
            is_admin: false,
            type: 0,
            allow_finance: 0,
            allow_sub_rules: 0,
            allow_ticket_rules: 0,
        };
    }
    const { payload } = res.data;
    return {
        authenticated: true,
        token: payload.token,
        organisation_id: payload.organisation_id,
        user_id: payload.user_id,
        username: payload.user_name,
        permissions: payload.permissions,
        email: payload.email,
        is_admin: payload.is_admin,
        type: payload.type,
        allow_finance: payload.allow_finance,
        allow_sub_rules: payload.allow_sub_rules,
        allow_ticket_rules: payload.allow_ticket_rules,
    };
};

const forgotPassword = async (email: string): Promise<boolean> => {
    const res = await authBase.post('/forgotPassword', {
        email,
    });
    if (res?.status === 200) {
        return true;
    }
    return false;
};

const changePassword = async (
    user_id: number,
    password: string,
    new_password: string
) => {
    const res = await apiBase.put('/user/password/update', {
        user_id,
        password,
        new_password,
    });

    if (res.status === 200) {
        return true;
    }
    return false;
};

const validateToken = async (req: ValidateTokenRequest): Promise<number> => {
    const res = await authBase.get(`/validate/${req.token}`);

    return res.status;
};

export const authApi = {
    authenticate,
    validateToken,
    forgotPassword,
    changePassword,
};
